export const usePostSave = () => {
  const savedPost = useState<PostItem>("post-save-item");
  const savedImage = useState<ImageItem | undefined>("post-save-image");
  const isSaveOpen = useState<boolean>("post-save-open", () => false);

  const savePost = (
    post: MaybeRef<PostItem>,
    image?: MaybeRef<ImageItem | undefined>
  ) => {
    savedPost.value = unref(post);
    savedImage.value = unref(image);
    nextTick(() => {
      isSaveOpen.value = true;
    });
  };

  const closeSave = () => {
    isSaveOpen.value = false;
  };

  return {
    savedPost,
    savedImage,
    isSaveOpen,
    savePost,
    closeSave,
  };
};
